import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import DynamicRoute from 'components/blocks/DynamicRoute';
import Loader from 'components/organisms/Loader';

import { DEFAULT_NOT_FOUND_SLUG } from 'constants/common';
import { FETCH_ROUTES_LIST } from 'storage/modules/routes/actions/fetchRoutesList';

import { ServerErrorPages } from 'pages/Public/Pages';
import * as ADMIN_PAGES from 'pages/Admin/Pages';

import AppLayoutRoute from './withLayouts/AppRoute';
import AdminLayoutRoute, { ADMIN_PATHS } from './withLayouts/AdminRoute';
import StaticLayoutRoute from './withLayouts/StaticRoute';

export default function Routes() {
  const dispatch = useDispatch();
  const { data: routes, statuses } = useSelector((state) => state.routes);

  useEffect(() => {
    if (statuses.initial) {
      dispatch({ type: FETCH_ROUTES_LIST });
    }
  }, [dispatch, statuses.initial]);

  if (statuses.initial || statuses.loading) {
    return <Loader />;
  }

  return (
    <Switch>
      <AdminLayoutRoute
        key={ADMIN_PATHS.ROUTES_PAGE}
        path={ADMIN_PATHS.ROUTES_PAGE}
        component={ADMIN_PAGES.RoutesPage}
      />
      <AdminLayoutRoute
        key={ADMIN_PATHS.PAGES_PAGE}
        path={ADMIN_PATHS.PAGES_PAGE}
        component={ADMIN_PAGES.PagesPage}
      />
      <AdminLayoutRoute
        key={ADMIN_PATHS.STAFFS_PAGE}
        path={ADMIN_PATHS.STAFFS_PAGE}
        component={ADMIN_PAGES.StaffsPage}
      />
      <AdminLayoutRoute
        key={ADMIN_PATHS.SECTIONS_PAGE}
        path={ADMIN_PATHS.SECTIONS_PAGE}
        component={ADMIN_PAGES.SectionsPage}
      />
      <AdminLayoutRoute
        key={ADMIN_PATHS.USERS_PAGE}
        path={ADMIN_PATHS.USERS_PAGE}
        component={ADMIN_PAGES.UsersPage}
      />
      <AdminLayoutRoute
        key={ADMIN_PATHS.CATEGORIES_PAGE}
        path={ADMIN_PATHS.CATEGORIES_PAGE}
        component={ADMIN_PAGES.CategoriesPage}
      />
      <AdminLayoutRoute
        key={ADMIN_PATHS.BOUNDARIES_PAGE}
        path={ADMIN_PATHS.BOUNDARIES_PAGE}
        component={ADMIN_PAGES.BoundariesPage}
      />
      <AdminLayoutRoute
        key={ADMIN_PATHS.POINTS_PAGE}
        path={ADMIN_PATHS.POINTS_PAGE}
        component={ADMIN_PAGES.PointsPage}
      />
      <AdminLayoutRoute
        key={ADMIN_PATHS.LOGIN_PAGE}
        path={ADMIN_PATHS.LOGIN_PAGE}
        component={ADMIN_PAGES.LoginPage}
        showSimpleLayout
      />
      {(routes ?? []).map((route) => (
        <AppLayoutRoute key={route.key} path={route.path} component={DynamicRoute} exact />
      ))}
      {Object.entries(ServerErrorPages).map(([lang, component]) => (
        <StaticLayoutRoute
          key={`${lang}-ServerErrorPage`}
          path={`/${lang}/50x.html`}
          component={component}
        />
      ))}
      <StaticLayoutRoute
        key="NotFoundPage"
        component={DynamicRoute}
        slug={DEFAULT_NOT_FOUND_SLUG}
      />
    </Switch>
  );
}
